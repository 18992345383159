.nav-tabs .nav-item .nav-link {
    color: black;
}
  
.nav-tabs .nav-item .nav-link.active {
    color: hsla(37, 90%, 51%, 1)
}

.accordion-button:focus {
    z-index: 3;
    border-color: #000 !important;
    outline: 0;
    box-shadow: 0 0 0 .25rem #FFF !important;
}

.accordion-button {
    color : black;
    font-weight: bold;
}

.accordion-button:not(.collapsed) {
    color : black;
    font-weight: bold;
    background-color:hsla(37, 90%, 51%, 1);
}

